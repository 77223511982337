import axios from "axios";
import app from "@/env";
import { getCookies } from "@/helpers/store-cookies";
import ls from "localstorage-slim";

// Create an Axios instance
export const AXIOS = axios.create({
  baseURL: app.baseURL,
  headers: {
    Authorization: `Bearer ${getCookies("access_token")}`,
    ContentType: "application/x-www-form-urlencoded",
    XTenant: `${localStorage.getItem("xtenant")}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    "Accept-Language": ls.get("locale") || "en",
  },
});

// Add a response interceptor to handle all errors
AXIOS.interceptors.response.use(
  (response) => response, // Pass successful responses through
  (error) => {
    // Throw an error for any status code that indicates a failure
    if (error.response) {
       throw error
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      throw new Error(`Request error: ${error.message}`);
    }
  }
);
