import { defineAbility } from "@casl/ability";
import ls from "localstorage-slim";

ls.config.encrypt = true;

var permissions = ls.get("per");

// (permissions);

export default (type) =>
  defineAbility((can) => {
    switch (type) {
      case "admin":
        can(["create", "delete"], "User");
        can(["create", "delete", "read", "update"], "Sales");
        can(["create", "delete", "read", "update"], "Receive Payment");
        can(["create", "delete", "read", "update"], "Quotes");
        can(["create", "delete", "read", "update"], "Convert Quotes to Sales");
        can(["create", "delete", "read", "update"], "Customers");
        can(["create", "delete", "read", "update"], "Products & Services");
        can(["create", "delete", "read", "update"], "Expenses");
        can(["create", "delete", "read", "update"], "Bills");
        can(["create", "delete", "read", "update"], "Make Payment");
        can(["create", "delete", "read", "update"], "Vendors");
        
        can(["create", "delete", "read", "update"], "Projects");
        can(["create", "delete", "read", "update"], "Settings");
        can(["create", "delete", "read", "update"], "Dashboard");
        can(["create", "delete", "read", "update"], "Roles & Permissions");
        can(["create", "delete", "read", "update"], "Branches");
        can(["create", "delete", "read", "update"], "Payment method");
        can(["delete"], "Plan");
        can(["create", "delete", "read", "update"], "Charts Of Accounts");
        can("read", "Bank accounts");
        can(["create"], "Journal Entry");
        can(["read"], "Reports");
        can(["read"], "profitAndLoss");
        can(["read"], "VendorReport");
        can(["read"], "CustomerReport");

        can(["read"], "journalEntry");
        can(["read"], "generalLedger");
        can(["read"], "trialBalance");
        can(["read"], "detailedTrialBalance");

        can(["read"], "balanceSheet");
        can(["read"], "billsReport");
        can(["read"], "VATReport");
        can(["read"], "VATExpenseReport");
        can(["create", "read"], "Notes and reminders");
        break;
      case "accountant":
        can(["create", "delete", "read", "update"], "Dashboard");
        can(["create", "delete", "read", "update"], "Quotes");
        can(["create", "delete", "read", "update"], "Sales");
        can(["create", "delete", "read", "update"], "Expenses");
        can(["create", "delete", "read", "update"], "Bills");
        can("read", "Bank accounts");
        can(["create"], "Journal Entry");
        can(["read"], "Reports");
        can(["read"], "profitAndLoss");
        can(["read"], "VendorReport");
        can(["read"], "CustomerReport");

        can(["read"], "journalEntry");
        can(["read"], "generalLedger");
        can(["read"], "trialBalance");
        can(["read"], "detailedTrialBalance");

        can(["read"], "balanceSheet");
        can(["read"], "billsReport");
        can(["create", "delete", "read", "update"], "Receive Payment");
        can(["create", "delete", "read", "update"], "Make Payment");
        break;
      case "reg_user":
        // can(['create', 'read', 'update'], 'Sales');
        // can(['create', 'read', 'update'], 'Quotes');
        // can(['read'], 'Customers');
        // can(['read'], 'Products & Services');
        // can(['create', 'read', 'update'], 'Expenses');
        // can(['create', 'read', 'update'], 'Bills');
        // can(['read'], 'Vendors');
        // can(['read'], 'Projects');
        // can(['create'], 'Journal Entry');
        // can(['read'], 'Profit and Loss');
        // can(['read'], 'Journal');
        // can(['read'], 'General Ledger');
        // can(['read'], 'Trial Balance');
        // can(['read'], 'Balance Sheet');
        can(["create", "delete", "read", "update"], "Quotes");
        can(["create", "delete", "read", "update"], "Sales");
        can(["create", "delete", "read", "update"], "Expenses");
        can(["create", "delete", "read", "update"], "Bills");
        can("read", "Bank accounts");
        can(["create"], "Journal Entry");
        can(["read"], "Reports");
        can(["read"], "profitAndLoss");
        can(["read"], "VendorReport");
        can(["read"], "CustomerReport");

        can(["read"], "journalEntry");
        can(["read"], "generalLedger");
        can(["read"], "trialBalance");
        can(["read"], "detailedTrialBalance");

        can(["read"], "balanceSheet");
        can(["read"], "billsReport");
        can(["create", "delete", "read", "update"], "Receive Payment");
        can(["create", "delete", "read", "update"], "Make Payment");
        break;
      case "PM":
        // from Projects by id only
        can(["create"], "Sales");
        can(["create"], "Expenses");
        can(["create"], "Bills");
        can(["create", "delete", "read", "update"], "Projects");
        break;
    }
  });
