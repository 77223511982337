import { AXIOS } from "../../../axios";
import { successToast } from "@/helpers/toastifications";

export default {
  namespaced: true,
  state: {
    customers: [],
    currencies: [],
    loading: true,
    customerBarLineChart: {},
    customersStats: {},
    nameExistMsg: "",
    refreshCustomerList: 0,
    customersForInvoice: [],
    customerById: {},
    viewLoading: true,
    addingCustomer: false,
    autoSelectedCustomer: {},
    showTable: false,
    countries: [],
    
  },
  getters: {
    customers: (state) => state.customers,

    currencies: (state) => state.currencies,

    loading: (state) => state.loading,

    customerBarLineChart: (state) => state.customerBarLineChart,

    customersStats: (state) => state.customersStats,

    nameExistMsg: (state) => state.nameExistMsg,

    refreshCustomerList: (state) => state.refreshCustomerList,

    customersForInvoice: (state) => state.customersForInvoice,

    customerById: (state) => state.customerById,

    viewLoading: (state) => state.viewLoading,

    addingCustomer: (state) => state.addingCustomer,

    autoSelectedCustomer: (state) => state.autoSelectedCustomer,

    showTable: (state) => state.showTable,
    countries: (state) => state.countries,
  },
  mutations: {
    setCustomers: (state, data) => {
      state.customers = data;
    },

    setCurrencies: (state, data) => {
      state.currencies = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_customerBarLineChart: (state, data) => {
      state.customerBarLineChart = data;
    },

    set_customersStats: (state, data) => {
      state.customersStats = data;
    },

    setNameExistMsg: (state, data) => {
      state.nameExistMsg = data;
    },

    set_refreshCustomerList: (state, data) => {
      state.refreshCustomerList = data;
    },

    set_customersForInvoice: (state, data) => {
      state.customersForInvoice = data;
    },

    set_customerById: (state, data) => {
      state.customerById = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_addingCustomer: (state, data) => {
      state.addingCustomer = data;
    },

    set_autoSelectedCustomer: (state, data) => {
      state.autoSelectedCustomer = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },
    set_countries: (state, data) => {
      state.countries = data;
    },
  },
  actions: {
    openAddCustomerPopup() {
      const addCustomerModal = document.getElementById("addCustomerModal");
      addCustomerModal.style.display = "flex";
      // ('actions openAddCustomerPopup');
    },

    closeAddCustomerPopup({ dispatch }) {
      const addCustomerModal = document.getElementById("addCustomerModal");
      addCustomerModal.style.display = "none";
      dispatch("getCustomersAction");
    },

    getCustomersAction: ({ commit }, search) => {
      AXIOS.post(`Customer/GetCustomersTable`, { search: search })
        .then((res) => {
          // (res.data);
          commit("setCustomers", res.data.Customers);
          commit("set_showTable", res.data.ShowTable);

          // ('mbtgeesh');
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });

      // ('actions getCustomersAction');
    },

    GetCustomerForInvoiceAction: ({ commit }) => {
      AXIOS.get(`Forms/GetCustomerForInvoice`)
        .then((res) => {
          console.log(res.data.Customers, "customers" , window.location.href);
          commit("set_customersForInvoice", res.data.Customers);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_refreshCustomerList_action: ({ state, commit }) => {
      commit("set_refreshCustomerList", ++state.refreshCustomerList);
    },

    addCustomerAction: ({ commit, dispatch, state }, payload) => {
      // dispatch('set_refreshCustomerList_action', false);
      commit("set_addingCustomer", true);
      return AXIOS.post(`Customer/AddCustomer`, {
        customer_first_name: payload.firstName,
        customer_middle_name: payload.middleName,
        customer_last_name: payload.lastName,
        company_name: payload.company,
        display_name: payload.displayName,
        email: payload.email,
        billing_email: payload.billing_email,
        phone: payload.phone,
        mobile: payload.mobile,
        other: payload.other,
        website: payload.website,
        opening_balance: payload.opening_balance,
        opening_balance_date: payload.asOf,
        tax: payload.tax,
        address: payload.billing_address,
        city: payload.billing_city,
        state: payload.billing_state,
        postal_code: payload.billing_postal_code,
        country: payload.billing_country,
        shipping_address: payload.shipping_address,
        shipping_city: payload.shipping_city,
        shipping_state: payload.shipping_state,
        shipping_postal: payload.shipping_postal_code,
        shipping_country: payload.shipping_country,
        currency_id: payload.currency_id,
        billing_address: payload.billing_address,
        billing_city: payload.billing_city,
        billing_state: payload.billing_state,
        billing_postal_code: payload.billing_postal_code,
        billing_country_id: payload.billing_country,
        foreign_opening: payload.foreign_opening,
        customer_type: payload.customer_type,
        national_id: payload.national_id,
        country_id: payload.country_id,
        tax: payload.tax,
      })
        .then((res) => {
          if (res.status === 200) {
            
            commit("set_addingCustomer", false);
            // dispatch("closeAddCustomerPopup");
            commit("set_autoSelectedCustomer", res.data.Customers);
          }
          return res;
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_addingCustomer", false);
        });
    },

    editCustomerAction: ({ commit, dispatch }, payload) => {
      // dispatch('set_refreshCustomerList_action', false);
      return AXIOS.post(`Customer/EditCustomer/${payload.id}`, {
        customer_first_name: payload.firstName,
        customer_middle_name: payload.middleName,
        customer_last_name: payload.lastName,
        company_name: payload.company,
        display_name: payload.displayName,
        email: payload.email,
        billing_email: payload.billing_email,
        phone: payload.phone,
        mobile: payload.mobile,
        other: payload.other,
        website: payload.website,
        opening_balance: payload.openingBalance,
        opening_balance_date: payload.asOf,
        foreign_opening_balance: payload.foreign_opening_balance,
        tax: payload.tax,
        address: payload.billing_address,
        city: payload.billing_city,
        state: payload.billing_state,
        postal_code: payload.billing_postal_code,
        country: payload.billing_country,
        shipping_address: payload.shipping_address,
        shipping_city: payload.shipping_city,
        shipping_state: payload.shipping_state,
        shipping_postal: payload.shipping_postal_code,
        shipping_country_id: payload.shipping_country,
        currency_id: payload.currency_id,
        billing_address: payload.billing_address,
        billing_city: payload.billing_city,
        billing_state: payload.billing_state,
        billing_postal_code: payload.billing_postal_code,
        billing_country: payload.billing_country,
        customer_type: payload.customer_type,
        national_id: payload.national_id,
      })
        .then((res) => {
          if (res.status === 200) {
            // successToast("Edited Successfully");
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    getCurrenciesAction: ({ commit }) => {
      AXIOS.get(`Forms/GetAllCurrency`)
        .then((res) => {
          // (res.data);
          commit("setCurrencies", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getCountries: ({ commit }) => {
      AXIOS.get(`api/GetAllCountries`)
        .then((res) => {
          // (res.data);
          commit("set_countries", res.data.Countries);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_customerBarLineChart_action: ({ commit }) => {
      AXIOS.post("Customer/SalesVsCustomersChart", {})
        .then((res) => {
          commit("set_customerBarLineChart", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_customersStats_action: ({ commit }) => {
      AXIOS.post("Customer/CustomerStatsChart", {})
        .then((res) => {
          commit("set_customersStats", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    checkNameExistAction: ({ commit }, name) => {
      AXIOS.get(`Customer/CompanyNameExist/${name}`)
        .then((res) => {
          // (res);
          if (res.status === 200) {
            commit("setNameExistMsg", "");
          }
        })
        .catch((err) => {
          err.message;
          // (err.response.status);
          // if(err.response.status === 409)  {
          //     ('409');
          // }
        });
    },

    setNameExistMsgAction: ({ commit }, data) => {
      commit("setNameExistMsg", data);
      // ('from action', data);
    },

    get_customerById_action: ({ commit }, id) => {
      AXIOS.post(`Customer/CustomerDetails/${id}`, {})
        .then((res) => {
          console.log(JSON.stringify(res.data.CustomerDetails , null, 4) ,"customerDetails");
          commit("set_customerById", res.data.CustomerDetails);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    deleteCusromerAction: ({ dispatch }, payload) => {
      AXIOS.get(`Customer/DeleteCustomer/${payload.id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
