import axios from "axios";
import { successToast } from "@/helpers/toastifications";
import Vue from "vue";
import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    projects: [],
    loading: true,
    projectById: {},
    viewLoading: true,
    invoiceProject: "",
    location_list: [],
    autoSelectedProject: {},
    autoSelectedProjectLocation: {},
    showTable: false,
    addingProjectLocation: false,
    addingProject: false,
    defaultLocation: {},
    opeEdit:false
  },
  getters: {
    projects: (state) => state.projects,
    openEdit:(state)=> state.openEdit,
    loading: (state) => state.loading,
    projectById: (state) => state.projectById,
    viewLoading: (state) => state.viewLoading,
    invoiceProject: (state) => state.invoiceProject,
    location_list: (state) => state.location_list,
    getFirstLocation: (state) => state.location_list[0],
    autoSelectedProject: (state) => state.autoSelectedProject,
    autoSelectedProjectLocation: (state) => state.autoSelectedProjectLocation,
    showTable: (state) => state.showTable,
    addingProjectLocation: (state) => state.addingProjectLocation,
    addingProject: (state) => state.addingProject,
    defaultLocation: (state) => state.defaultLocation,
  },
  mutations: {
    set_projects: (state, data) => {
      state.projects = data;
    },
    set_defaultLocation: (state, data) => {
      state.defaultLocation = data;
    },
set_openEdit:(state,data) =>{
  state.opeEdit = data;

},
    set_loading: (state, data) => {
      state.loading = data;
    },

    set_projectById: (state, data) => {
      state.projectById = data;
    },

    closeAddProjectPopup() {
      const addProjectModal = document.getElementById("addProjectModal");
      addProjectModal.style.display = "none";
    },

    get_projects_action: ({ commit }, search) => {
       AXIOS.get("Projects/GetProjects", { search: search })
        .then((res) => {
            
          console.log("projects",res.data.Projects,windows.location.href)
          commit("set_projects", res.data.Projects);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },
    change_project_status_action: ({ commit }, payload) => {
      AXIOS.post(`Projects/ChangeProjectStatus`, {
        project_id: payload.id,
        project_status: payload.status,
      })
        .then((res) => {
          if (res.status === 200) {
            successToast(this.$t("Status changed successfully"));
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    get_location_list: ({ commit }) => {
      AXIOS.get("Projects/GetProjectLoction")
        .then((res) => {
          commit("set_location_list", res.data.Location);
          commit("set_defaultLocation", res.data.Location[0]);
        })
        .catch((err) => {
          err.message;
        });
    },

    add_Project: ({ dispatch, commit, state }, payload) => {
      AXIOS.post("Projects/AddProject", {
        project_name: payload.project_name,
        project_location: payload.project_location,
        customer_id: payload.customer_id,
        project_status: payload.project_status,
        project_note: payload.project_note,
        expected_revenue: payload.expected_revenue,
        expected_expenses: payload.expected_expenses,
        start_date: payload.start_date,
        end_date: payload.end_date,
        customer_note: payload.customer_note,
        poc: payload.poc,
        mobile: payload.mobile,
        customer_email: payload.customer_email,
      })
        .then((res) => {
          if (res.status === 200) {
            commit("addingProject");
            this.$emit("updateProjects");
            dispatch("closeAddProjectPopup");
            dispatch("get_projects_action");

            setTimeout(() => {
              commit(
                "set_autoSelectedProject",
                state.projects[state.projects.length - 1]
              );
            }, 1000);
          }
          return res;
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_addingProjectLocation", false);
        });
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_invoiceProject: (state, data) => {
      state.invoiceProject = data;
    },

    set_location_list: (state, data) => {
      state.location_list = data;
    },

    set_autoSelectedProject: (state, data) => {
      state.autoSelectedProject = data;
    },

    set_autoSelectedProjectLocation: (state, data) => {
      state.autoSelectedProjectLocation = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },

    set_addingProjectLocation: (state, data) => {
      state.addingProjectLocation = data;
    },
    set_addingProject: (state, data) => {
      state.addingProjectLocation = data;
    },
  },
  actions: {
    openAddProjectPopup() {
      const addProjectModal = document.getElementById("addProjectModal");
      addProjectModal.style.display = "flex";
    },

    closeAddProjectPopup() {
      const addProjectModal = document.getElementById("addProjectModal");
      addProjectModal.style.display = "none";
    },
    openEditProjectPopup({ commit ,dispatch }, id) {
        if(id == null || id == undefined || id == "" ) return;
        dispatch("get_projectById_action", id).then(() => {
        const editProjectModal = document.getElementById("editProjectModal");
        if (editProjectModal) {
          editProjectModal.style.display = "flex";
          commit("set_openEdit", true);

        }
      });
    },

    closeEditProjectPopup({commit }) {
      const addProjectModal = document.getElementById("editProjectModal");
      if(addProjectModal){
      addProjectModal.style.display = "none";
      commit("set_openEdit", false);

      }
    },

    openAddProjectLocationPopup() {
      const addProjectLocationModal = document.getElementById(
        "addProjectLocationModal"
      );
       addProjectLocationModal.style.display = "flex";
    },

    closeAddProjectLocationPopup() {
      const addProjectLocationModal = document.getElementById(
        "addProjectLocationModal"
      );
      addProjectLocationModal.style.display = "none";
    },
    openEditProjectLocationPopup() {
      const editProjectLocationModal = document.getElementById(
        "editProjectLocationModal"
      );
       editProjectLocationModal.style.display = "flex";
    },

    closeEditProjectLocationPopup() {
      const editProjectLocationModal = document.getElementById(
        "editProjectLocationModal"
      );
      editProjectLocationModal.style.display = "none";
    },

    get_projects_action: ({ commit }, search) => {
      AXIOS.get("Projects/GetProjects", { search: search })
        .then((res) => {
          console.log("projects",res.data.Projects,windows.location.href)

          commit("set_projects", res.data.Projects);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    get_projectById_action: ({ commit }, id) => {
      AXIOS.get(`Projects/ProjectByID/${id}`)
        .then((res) => {
          commit("set_projectById", res.data.Project);
          commit("set_invoiceProject", res.data.Project.ProjectName);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    change_project_status_action: ({ commit }, payload) => {
      AXIOS.post(`Projects/ChangeProjectStatus`, {
        project_id: payload.id,
        project_status: payload.status,
      })
        .then((res) => {
          if (res.status === 200) {
            successToast(this.$t("Status changed successfully"));
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    get_location_list: ({ commit }) => {
      AXIOS.get("Projects/GetProjectLoction")
        .then((res) => {
          commit("set_location_list", res.data.Location);
        })
        .catch((err) => {
          err.message;
        });
    },

    add_location_action: ({ dispatch, commit, state }, payload) => {
      commit("set_addingProjectLocation", true);
      return AXIOS.post("Projects/AddProjectLocation", {
        project_location: payload.location,
      })
        .then((res) => {
          if (res.status === 200) {
            commit("set_addingProjectLocation", false);
            dispatch("get_location_list");
            dispatch("closeAddProjectLocationPopup");
            setTimeout(() => {
              commit(
                "set_autoSelectedProjectLocation",
                state.location_list[state.location_list.length - 1]
              );
            }, 1000);
          }
          return res;
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_addingProjectLocation", false);
        });
    },
    set_autoSelectedProject_action: ({ commit, state, dispatch }) => {
      dispatch("get_projects_action");
      "prjects from store", state.projects[state.projects.length - 1];
      commit(
        "set_autoSelectedProject",
        state.projects[state.projects.length - 1]
      );
    },
  },
  modules: {},
};
