import { AXIOS } from "../../../axios";
import { successToast } from "@/helpers/toastifications";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    bills: [],
    terms: [],
    coa: [],
    invnetory_items: [],
    nonInventory_service: [],
    loading: true,
    billbyID: {},
    vendorsBills: [],
    viewLoading: true,
    billNextItem: "",
    showTable: false,
    makePaymentNextItem: "",
    autoSelectedTerm: {},
    makepayment_id: "",
    makePaymentById: {},
    BranchesEnable: 0,
    itemsForBill: [],
    autoSelectedProductOrCoa: {},
  },
  getters: {
    bills: (state) => state.bills,
    terms: (state) => state.terms,
    coa: (state) => state.coa,
    invnetory_items: (state) => state.invnetory_items,
    nonInventory_service: (state) => state.nonInventory_service,
    loading: (state) => state.loading,
    billbyID: (state) => state.billbyID,
    vendorsBills: (state) => state.vendorsBills,
    viewLoading: (state) => state.viewLoading,
    billNextItem: (state) => state.billNextItem,
    makePaymentNextItem: (state) => state.makePaymentNextItem,
    autoSelectedTerm: (state) => state.autoSelectedTerm,
    showTable: (state) => state.showTable,
    makepayment_id: (state) => state.makepayment_id,
    makePaymentById: (state) => state.makePaymentById,
    BranchesEnable: (state) => state.BranchesEnable,
    itemsForBill: (state) => state.itemsForBill,
    autoSelectedProductOrCoa: (state) => state.autoSelectedProductOrCoa,
  },
  mutations: {
    setBills: (state, data) => {
      state.bills = data;
    },

    setTerms: (state, data) => {
      state.terms = data;
    },

    setcoa: (state, data) => {
      state.coa = data;
    },

    set_invnetory_items: (state, data) => {
      state.invnetory_items = data;
    },

    set_nonInventory_service: (state, data) => {
      state.nonInventory_service = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_billbyID: (state, data) => {
      state.billbyID = data;
    },

    set_vendorsBills: (state, data) => {
      state.vendorsBills = data;
    },

    set_billNextItem: (state, data) => {
      state.billNextItem = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },

    set_makePaymentNextItem: (state, data) => {
      state.makePaymentNextItem = data;
    },

    set_autoSelectedTerm: (state, data) => {
      state.autoSelectedTerm = data;
    },

    set_makepayment_id: (state, data) => {
      state.makepayment_id = data;
    },

    set_makePaymentById: (state, data) => {
      state.makePaymentById = data;
    },
    set_BranchesEnable: (state, data) => {
      state.BranchesEnable = data;
    },
    set_itemsForBill: (state, data) => {
      state.itemsForBill = data;
    },

    set_autoSelectedProductOrCoa: (state, data) => {
      state.autoSelectedProductOrCoa = data;
    },
  },
  actions: {
    openAddBillPopup() {
      const addBillModal = document.getElementById("addBillModal");
      addBillModal.style.display = "flex";
    },

    closeAddBillPopup() {
      const addBillModal = document.getElementById("addBillModal");
      addBillModal.style.display = "none";
    },

    openAddTermPopup() {
      const addTermModal = document.getElementById("addTermModal");
      addTermModal.style.display = "flex";
    },

    closeAddTermPopup() {
      const addTermModal = document.getElementById("addTermModal");
      addTermModal.style.display = "none";
    },

    closeMakePaymentPopup() {
      const makePaymentModal = document.getElementById("makePaymentModal");
      makePaymentModal.style.display = "none";
    },

    openMakePaymentPopup() {
      const makePaymentModal = document.getElementById("makePaymentModal");
      makePaymentModal.style.display = "flex";
    },

    getBillsAction: ({ commit }, search) => {
      AXIOS.post("Bill/GetBillResources", { search: search })
        .then((res) => {
          commit("setBills", res.data.Bills);
          commit("set_billNextItem", res.data.NextItem);
          commit("set_BranchesEnable", res.data.BranchesEnable);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    get_billbyID_action: ({ commit }, id) => {
      AXIOS.get(`Bill/GetBillByID/${id}`)
        .then((res) => {
          commit("set_billbyID", res.data);
          commit("set_makePaymentNextItem", res.data.NextItem);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },
    get_itemsForBill_action: ({ commit, dispatch }) => {
      AXIOS.get("Forms/GetItemsForBill", {})
        .then((res) => {
          commit("set_itemsForBill", res.data);
          dispatch("set_autoSelectitemsForBill_action");
        })
        .catch((err) => {
          err.message;
        });
    },

    getTermsAction: ({ commit }) => {
      AXIOS.get("Forms/GetTerms")
        .then((res) => {
          console.log("terms", res.data, window.location.href);
          commit("setTerms", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getCOAAction: ({ commit }) => {
      AXIOS.post("Forms/ListOFCOA", {})
        .then((res) => {
          commit("setcoa", res.data.COAList);
        })
        .catch((err) => {
          err.message;
        });
    },

    addTermAction: ({ commit, dispatch, state }, payload) => {
      return AXIOS.post("Terms/AddTerms", {
        terms_name: payload.terms_name,
        days: payload.days,
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch("getTermsAction");
            setTimeout(() => {
              commit(
                "set_autoSelectedTerm",
                state.terms[state.terms.length - 1]
              );
            }, 1000);
            dispatch("closeAddTermPopup");
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    get_invnetory_items_action: ({ commit }) => {
      AXIOS.get("Products/GetProductinventory")
        .then((res) => {
          commit("set_invnetory_items", res.data.Products);
        })
        .catch((err) => {
          err.message;
        });
    },

    nonInventory_service_Action: ({ commit }) => {
      AXIOS.get("Products/GetProductServiceAndNonInventory")
        .then((res) => {
          commit("set_nonInventory_service", res.data.Products);
        })
        .catch((err) => {
          err.message;
        });
    },

    update_vendorsBills_action: ({ commit }, payload) => {
      commit("set_vendorsBills", payload);
    },

    set_bill_preview_object: ({ commit }, payload) => {
      localStorage.setItem("bill_payload", JSON.stringify(payload));
    },

    deleteBillAction: ({ dispatch }, payload) => {
      AXIOS.get(`Bill/DeleteBill/${payload.id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_makepayment_id_action: ({ commit }, data) => {
      commit("set_makepayment_id", data);
    },

    get_makePaymentById_action: ({ commit }, id) => {
      AXIOS.get(`Bill/MakePaymentByID/${id}`)
        .then((res) => {
          commit("set_makePaymentById", res.data.Invoice);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_autoSelectitemsForBill_action: ({ commit, state }) => {
      commit(
        "set_autoSelectedProductOrCoa",
        state.itemsForBill[state.itemsForBill.length - 1]
      );
    },

    // set_autoSelectedProductOrCoa_action: ({ commit, state }) => {
    //   commit(
    //     "set_autoSelectedProductOrCoa",
    //     state.itemsForBill[state.itemsForBill.length - 1]
    //   );
    // },
  },
  modules: {},
};
